import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'

import { Head } from '../modules/layout/components/head'
import { PageLayout } from '../modules/layout/components/page-layout'
import { isStoryblokFooter } from '../modules/storyblok/components/footer/Footer.types'
import { isStoryblokNavigation } from '../modules/storyblok/components/navigation'
import { isStoryblokSponsors } from '../modules/storyblok/components/sponsors/Sponsors.types'
import { TranslationsProvider } from '../modules/storyblok/context/translations-provider'
import { getStoryblokStoryVersion } from '../modules/storyblok/utils/env'

import type { ISbStoryData, ISbStoryParams } from '@storyblok/react'
import type { GetStaticPaths, GetStaticPathsResult, GetStaticProps } from 'next'
import type { StoryblokDatasourceEntry } from 'types'

const RESOLVE_RELATIONS = [
  'match_calendar.matches',
  'match.league',
  'match.home_team',
  'match.away_team',
  'player_card.player',
  'match_widget.highlighted_match',
  'player.player_page',
  'player_stats.player',
]

type PageProps = {
  story: ISbStoryData
  relations: ISbStoryData[]
  translations: StoryblokDatasourceEntry[]
  navigationStory?: ISbStoryData
  footerStory?: ISbStoryData
  sponsorsStory?: ISbStoryData
  locale?: string
  locales?: string[]
  key: number
}

export default function Page({
  story: initialStory,
  relations,
  translations,
  navigationStory,
  footerStory,
  sponsorsStory,
  locale,
  locales,
}: PageProps) {
  const story = useStoryblokState(initialStory)
  const { seo_metatags } = story?.content || {}

  return (
    <TranslationsProvider locale={locale ?? 'nl'} translations={translations}>
      {story && <Head {...{ story, seo_metatags }} />}
      <PageLayout
        navigation={
          navigationStory?.content &&
          isStoryblokNavigation(navigationStory.content)
            ? navigationStory.content
            : undefined
        }
        footer={
          footerStory?.content && isStoryblokFooter(footerStory.content)
            ? footerStory.content
            : undefined
        }
        sponsors={
          sponsorsStory?.content && isStoryblokSponsors(sponsorsStory.content)
            ? sponsorsStory.content
            : undefined
        }
        locale={locale}
        locales={locales}
      >
        <StoryblokComponent
          blok={story?.content}
          locale={locale ?? 'nl'}
          relations={relations}
          translations={translations}
        />
      </PageLayout>
    </TranslationsProvider>
  )
}

export const getStaticProps: GetStaticProps = async ({
  params: { slug } = {},
  locale,
  locales,
}) => {
  const slugSanitized = Array.isArray(slug) ? slug.join('/') : slug
  const sbSlug = slugSanitized ?? 'home'

  const storyParameters: ISbStoryParams = {
    resolve_relations: RESOLVE_RELATIONS.join(','),
    resolve_links: 'url',
    version: getStoryblokStoryVersion(),
    language: locale,
    cv: Date.now(),
  }

  const storyblokApi = getStoryblokApi()

  let storyData

  const { data: redirectsData } = await storyblokApi.get(
    'cdn/datasource_entries',
    {
      dimension: locale,
      per_page: 1000,
      datasource: 'redirects',
      cv: Date.now(),
    }
  )

  const fullSlug = `/${locale}/${sbSlug}`

  try {
    const { data } = await storyblokApi.get(
      `cdn/stories/${sbSlug}`,
      storyParameters
    )

    storyData = data
  } catch {
    const matchingRedirect = (
      redirectsData.datasource_entries as StoryblokDatasourceEntry[]
    ).find((redirect) => redirect.name === fullSlug)

    if (matchingRedirect?.value) {
      return {
        redirect: {
          destination: matchingRedirect.value as string,
          permanent: false,
        },
        revalidate: 60,
      }
    }

    if (fullSlug.includes('/news/')) {
      return {
        redirect: {
          destination: `${locale === 'nl' ? '' : `/${locale}`}/news/articles`,
          permanent: false,
        },
        revalidate: 60,
      }
    }

    return {
      notFound: true,
      revalidate: 60,
    }
  }

  if (
    fullSlug.includes('/news/articles/') &&
    !(storyData.story.content.languages || ['nl']).includes(locale)
  ) {
    return {
      notFound: true,
      revalidate: 60,
    }
  }

  const { data: translationsData } = await storyblokApi.get(
    'cdn/datasource_entries',
    {
      dimension: locale,
      per_page: 1000,
      datasource: 'translations',
      cv: Date.now(),
    }
  )

  const { data: navigationData } = await storyblokApi.get(
    `cdn/stories/configuration/navigation`,
    storyParameters
  )
  const { data: footerData } = await storyblokApi.get(
    `cdn/stories/configuration/footer`,
    storyParameters
  )
  const { data: sponsorsStory } = await storyblokApi.get(
    `cdn/stories/configuration/sponsors`,
    storyParameters
  )

  return {
    props: {
      story: storyData.story,
      relations: storyData.rels || [],
      translations: translationsData.datasource_entries,
      key: storyData.story.id,
      navigationStory: navigationData.story,
      footerStory: footerData.story,
      sponsorsStory: sponsorsStory.story,
      locale,
      locales,
    },
    revalidate: 60,
  }
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const storyblokApi = getStoryblokApi()

  const { data } = await storyblokApi.get('cdn/links/', {
    version: getStoryblokStoryVersion(),
  })

  const paths: GetStaticPathsResult['paths'] = []

  Object.keys(data.links).forEach((linkKey) => {
    if (
      data.links[linkKey].is_folder ||
      data.links[linkKey].slug === 'my-cercle' ||
      data.links[linkKey].slug === 'welcome' ||
      data.links[linkKey].slug === 'login' ||
      data.links[linkKey].slug === 'verify-email' ||
      data.links[linkKey].slug === 'not-found'
    ) {
      return
    }

    const slug = data.links[linkKey].slug as string
    const splitSlug = slug.split('/') as string[]

    // Ignore all configuration slugs
    if (splitSlug[0] === 'configuration') {
      return
    }

    // Ignore articles
    if (splitSlug[0] === 'news') {
      return
    }

    if (locales && locales.length > 0) {
      for (const locale of locales) {
        paths.push({ params: { slug: splitSlug }, locale })
      }
    } else {
      paths.push({ params: { slug: splitSlug } })
    }
  })

  return {
    paths: paths,
    fallback: 'blocking',
  }
}
